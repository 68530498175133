import { styled } from '@mui/material/styles'

import { CheckCircleOutlinedIcon, CopyOutlinedIcon } from '@icons'
import CopyToClipboard from '@components/CopyToClipboard'

const StyledIcon = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 18,
  cursor: 'pointer',
}))

export default function CopyIconButton({ fn }) {
  return (
    <CopyToClipboard>
      {({ copy, copied }) => (
        <StyledIcon onClick={() => fn(copy)}>{copied ? <CheckCircleOutlinedIcon /> : <CopyOutlinedIcon />}</StyledIcon>
      )}
    </CopyToClipboard>
  )
}
